import {
  Action,
  ActionCreator,
  applyMiddleware,
  combineReducers,
  createStore,
} from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import { persistReducer, persistStore } from 'redux-persist'
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2'
import storage from 'redux-persist/lib/storage'
import thunk, { ThunkAction, ThunkMiddleware } from 'redux-thunk'

import eventReducer, { IEventActions } from './Event/reducers'

// thunk types
type Thunk<R> = ThunkAction<R, IReduxState, undefined, Action>
export type TAction<R> = ActionCreator<Thunk<R>>

// redux types
export type IReduxState = ReturnType<typeof rootReducer>
export type IReduxActions = IEventActions // & otherActions

// construct the store
const rootReducer = combineReducers({
  event: eventReducer,
  // & other: otherReducer,
})

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
}

const persistedReducer = persistReducer<IReduxState>(persistConfig, rootReducer)

export const store = createStore(
  persistedReducer,
  composeWithDevTools(
    applyMiddleware(thunk as ThunkMiddleware<IReduxState, Action>)
  )
)

export const persistor = persistStore(store)
