import { Reducer } from 'redux'
import { ActionType } from 'typesafe-actions'
import { IEvent } from './types'
import {
  LOAD_EVENT_SUCCESS,
  LOAD_EVENT_FAILURE,
  RESET_EVENT_STORE,
  SAVE_SELECTED_VIDEO_ID,
} from './constants'

export type IEventActions = ActionType<typeof import('./actions')>

export type IEventState = ImmutableObject<IEvent & { selectedVideoId: string }>

const initState: IEventState = {
  id: '',
  name: '',
  path_display: '',
  path_lower: '',
  slug: '',
  files: [],
  selectedVideoId: '',
  passwordRequired: false,
}

const eventReducer: Reducer<IEventState, IEventActions> = (
  state = initState,
  action
) => {
  switch (action.type) {
    case LOAD_EVENT_SUCCESS:
      return { ...action.payload, selectedVideoId: state.selectedVideoId }
    case LOAD_EVENT_FAILURE:
      return initState
    case SAVE_SELECTED_VIDEO_ID:
      return {
        ...state,
        selectedVideoId: action.payload,
      }
    case RESET_EVENT_STORE:
      return initState
    default:
      return state
  }
}

export default eventReducer
