import styled from 'styled-components'
import HeaderBg from '../../static/Img/header_shapes.svg'

export const Header = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;

  background: url('${HeaderBg}') center no-repeat;
  background-size: cover;
`

export const Img = styled.img`
  height: 40px;
`
