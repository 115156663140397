import React, { useEffect } from 'react'
import vhCheck from 'vh-check'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom'

import routes from '../constants/routes'

// pages
import MainPage from '../pages/MainPage'
import EventDetail from '../pages/EventDetail'

const App: React.FC = () => {
  useEffect(() => {
    const recalculateVh = () => {
      vhCheck()
    }
    /**
     * to check when orientation si changed, because then vhChechk for iphones bottom bar is not working
     * and CTA buttons are hidden
     */
    window.addEventListener('orientationchange', recalculateVh)
    recalculateVh()
    return () => {
      window.removeEventListener('orientationchange', recalculateVh)
    }
  }, [])

  return (
    <React.Fragment>
      <Router>
        <Routes>
          <Route
            path={routes.videoDetail(':eventSlug', ':selectedVideoId?')}
            Component={EventDetail}
          />
          <Route
            path={routes.eventDetail(':eventSlug')}
            Component={EventDetail}
          />
          <Route path={routes.index} Component={MainPage} />
          <Route Component={NoMatch} />
        </Routes>
      </Router>
    </React.Fragment>
  )
}

// 404 route
const NoMatch: React.FC = () => <Navigate to={{ pathname: routes.index }} />

export default App
