import React, { useState } from 'react'
import { LazyLoadImage, ScrollPosition } from 'react-lazy-load-image-component'
import { useDispatch, useSelector } from 'react-redux'

import { IFile } from '../../../../data/Event/types'

import Spinner from '../../../../static/Svg/Spinner'
import { saveSelectedVideoId } from '../../../../data/Event/actions'
import { selectEventSlug } from '../../../../data/Event/selectors'
import routes from '../../../../constants/routes'
import * as S from './styled'

interface IProps {
  video: IFile
  thumbnail: IFile
  scrollPosition: ScrollPosition
}

const SingleImage: React.FC<IProps> = ({
  video,
  thumbnail,
  scrollPosition,
}) => {
  const [isLoading, setIsLoading] = useState(true)

  const eventSlug = useSelector(selectEventSlug)
  const dispatch = useDispatch()

  const clickHandler = (videoId: string) => {
    dispatch(saveSelectedVideoId(videoId))
  }
  return (
    <S.ImgWrap
      isLoading={isLoading}
      to={routes.videoDetail(eventSlug, video.id)}
      onClick={() => clickHandler(video.id)}
    >
      <LazyLoadImage
        src={thumbnail.url}
        alt={thumbnail.name}
        height={'30vh'}
        delayTime={0}
        scrollPosition={scrollPosition}
        onLoad={() => setIsLoading(false)}
        // keep this placeholder, because otherwise some of the images weren't loaded correctly
        placeholder={<span style={{ opacity: 0 }}>loading</span>}
      />
      {isLoading && (
        <S.LoaderWrap>
          <Spinner />
        </S.LoaderWrap>
      )}
    </S.ImgWrap>
  )
}

export default SingleImage
