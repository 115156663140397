import React from 'react'
import colors from '../../../constants/colors'

type IProps = {
  isInverted?: boolean
}

const SaveAlt: React.FC<IProps> = ({ isInverted }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      height='24'
      viewBox='0 0 24 24'
      width='24'
    >
      <path
        d='M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z'
        fill={isInverted ? colors.primary : colors.secondary}
      />
      <path d='M0 0h24v24H0z' fill='none' />
    </svg>
  )
}

export default SaveAlt
