/* stylelint-disable selector-list-comma-newline-after  */
import { createGlobalStyle } from 'styled-components'
import colors from './constants/colors'
import { fontFamily } from './constants/typography'

const GlobalStyle = createGlobalStyle`
  /* === CSS RESETS === */

  /* http://meyerweb.com/eric/tools/css/reset/
    v2.0 | 20110126
    License: none (public domain)
  */
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video, button {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
  }

  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  body {
    font-family: ${fontFamily.primary}, Arial, Helvetica, sans-serif;
    color: ${colors.secondary};
    line-height: 1;

    &.noscroll {
      overflow: hidden;
      height: 100vh;
      /* stylelint-disable-next-line length-zero-no-unit */
      min-height: calc(100vh - var(--vh-offset, 0px));
    }
  }

  html {
    font-size: 67.5%;
    color: ${colors.secondary};
  }

  * {
    box-sizing: border-box;
  }

  ol, ul {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote::before, blockquote::after,
  q::before, q::after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  strong {
    font-weight: 800;
  }
`
export default GlobalStyle
