import styled from 'styled-components'
import { SmallText } from '../../constants/typography'

export const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px 0 100px 0;
`

export const Img = styled.img`
  height: 40px;
  opacity: 0.5;
  margin-bottom: 32px;
`

export const Text = styled(SmallText)``
