import React from 'react'

import Header from '../Header'
import * as S from './styled'

type Props = React.PropsWithChildren<{}>

const Layout: React.FC<Props> = ({ children }) => {
  return (
    <S.Wrap>
      <Header />
      <S.ContentWrap>{children}</S.ContentWrap>
    </S.Wrap>
  )
}

export default Layout
