import { apiPost } from './apiClient'

type ISendByMailVariables = {
  recipient: string
  resourceId: string
}

type ISendByMailResponse = {
  message: string
}

export const requestPostSendByMail = async (vars: ISendByMailVariables) => {
  return await apiPost<ISendByMailResponse>(`/actions/send-by-email`, {
    body: JSON.stringify(vars),
  })
}
