import api from './apiClient'
import { IEvent } from '../data/Event/types'
import config from '../config'

const { REACT_APP_API_URL: apiUrl } = config

type IFetchEventVariables = {
  slug: string
  password?: string
}

type IFetchEventResponse = IEvent

type ICheckEventPasswordResponse = {
  success: boolean
  passwordRequired: boolean
}

export const requestFetchEvent = async (vars: IFetchEventVariables) => {
  const res = await api<IFetchEventResponse>(
    `/events/${vars.slug}?token=${vars.password}`
  )
  // construct resources urls
  const modified: IFetchEventResponse = {
    ...res,
    files: res.files.map((file) => ({
      ...file,
      video: {
        ...file.video,
        url: file.video?.id
          ? `${apiUrl}/resources/redirect/${file.video.id}`
          : undefined,
      },
      thumbnail: {
        ...file.thumbnail,
        url: file.thumbnail?.id
          ? `${apiUrl}/resources/redirect/${file.thumbnail.id}`
          : undefined,
      },
      gif: file.gif
        ? {
            ...file.gif,
            url: file.gif?.id
              ? `${apiUrl}/resources/redirect/${file.gif.id}`
              : undefined,
          }
        : undefined,
    })),
  }
  return modified
}

export const requestCheckEventpassword = async (vars: IFetchEventVariables) => {
  const res = await api<ICheckEventPasswordResponse>(
    `/events/${vars.slug}/check?token=${vars.password}`
  )
  return res
}
