import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { IVideoImageGroup } from '../../data/Event/types'
import routes from '../../constants/routes'
import { saveSelectedVideoId } from '../../data/Event/actions'
import { checkIfIOS } from '../../utils/helpers'

import ArrowBack from '../../static/Svg/ArrowBack'
import MailOutline from '../../static/Svg/MailOutline'
import Layout from '../../components/Layout'

import VideoPlayer from './VideoPlayer'
import GifPlayer from './GifPlayer'
import DownloadButton from './DownloadButton'
import MailForm from './MailForm'
import * as S from './styled'

type IProps = {
  data: ImmutableObject<IVideoImageGroup>
  eventSlug: string
}

enum ActionButtonsState {
  NORMAL = 'normal',
  MAIL = 'mail',
}

const VideoDetail: React.FC<IProps> = ({ data: { video, gif }, eventSlug }) => {
  const [actionButtonsState, setActionsButtonsState] =
    useState<ActionButtonsState>(ActionButtonsState.NORMAL)

  const dispatch = useDispatch()

  const navigate = useNavigate()

  const inputElementRef = useRef<HTMLInputElement | null>(null)

  // disable body scrolling
  useEffect(() => {
    const body = document.querySelector('body')
    body?.classList.add('noscroll')

    return () => {
      body?.classList.remove('noscroll')
    }
  }, [])

  const goBackToEvent = (event?: React.MouseEvent<HTMLElement>) => {
    event?.preventDefault()
    dispatch(saveSelectedVideoId(''))
    navigate(routes.eventDetail(eventSlug))
  }

  if (!video.url) {
    goBackToEvent()
    return null
  }

  return (
    <S.FixedWrap>
      <Layout>
        <S.InnerWrap>
          {gif?.url ? (
            <GifPlayer gifSrc={gif.url} />
          ) : (
            <VideoPlayer videoSrc={video.url} />
          )}

          {actionButtonsState === ActionButtonsState.NORMAL && (
            <S.ActionsWrap>
              <S.ButtonBack
                href={routes.eventDetail(eventSlug)}
                onClick={goBackToEvent}
                size='small'
                title='Zpět'
              >
                <ArrowBack isInverted={true} />
                <S.ButtonLabel>Zpět</S.ButtonLabel>
              </S.ButtonBack>

              {!checkIfIOS() && (
                <DownloadButton
                  // download gif is possible on ios
                  downloadUrl={checkIfIOS() && gif?.url ? gif.url : video.url}
                />
              )}

              <S.ButtonEmail
                onClick={() => {
                  setActionsButtonsState(ActionButtonsState.MAIL)
                  inputElementRef.current?.focus()
                }}
                size='small'
                title='Poslat emailem'
              >
                <MailOutline isInverted={true} />
                <S.ButtonLabel>Poslat</S.ButtonLabel>
              </S.ButtonEmail>
            </S.ActionsWrap>
          )}

          {actionButtonsState === ActionButtonsState.MAIL && (
            <MailForm
              resourceId={video.id}
              onCancel={() => {
                setActionsButtonsState(ActionButtonsState.NORMAL)
              }}
            />
          )}
        </S.InnerWrap>
      </Layout>
    </S.FixedWrap>
  )
}

export default VideoDetail
