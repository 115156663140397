import styled from 'styled-components'
import BgPatternImg from '../../static/Img/bg_pattern.svg'

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* stylelint-disable-next-line length-zero-no-unit */
  min-height: calc(100vh - var(--vh-offset, 0px));
  max-width: 800px;
  margin: 0 auto;

  background: url('${BgPatternImg}') 0% 90% no-repeat;
  background-size: 100%;
`

export const ContentWrap = styled.div`
  flex: 1;
  padding: 2vw;

  display: flex;
  flex-direction: column;
`
