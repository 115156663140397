import { TAction } from '../store'
import {
  loadEventRequest,
  loadEventSuccess,
  loadEventFailure,
  resetEventStore,
} from './actions'
import * as eventApi from '../../api/events'
import { removeLeadingSlash } from '../../utils/stringUtils'
import { IApiClientError } from '../../api/apiClient'

/**
 * Fetches new data and saves it to the store
 * @param eventSlug slug of event to be fetched
 */
export const handleGetEventBySlug: TAction<
  Promise<{
    success: boolean
    requirePassword?: boolean
  }>
> = (eventSlug: string, password: string) => async (dispatch, getState) => {
  console.log('Fetching data for event', eventSlug)
  dispatch(loadEventRequest())

  // if the slug has changed, reset the store even before the response
  const { slug: currentSlug } = getState().event
  if (
    currentSlug &&
    removeLeadingSlash(currentSlug) !== removeLeadingSlash(eventSlug)
  ) {
    dispatch(resetEventStore())
  }

  // fetch new data
  try {
    const result = await eventApi.requestFetchEvent({
      slug: removeLeadingSlash(eventSlug),
      password,
    })
    dispatch(loadEventSuccess(result))
    return {
      success: true,
      requirePassword: undefined,
    }
  } catch (e) {
    const err = e as IApiClientError
    console.error(err)
    dispatch(loadEventFailure())
    return {
      success: false,
      requirePassword: err?.statusCode === 401,
    }
  }
}
