import React from 'react'
import { useNavigate } from 'react-router-dom'

import routes from '../../../constants/routes'
import PasswordForm from './PasswordForm'

type Props = {
  eventSlug: string
  onClose: () => void
  onSuccess: (password: string) => void
}

const PasswordModal: React.FC<Props> = ({ eventSlug, onClose, onSuccess }) => {
  const navigate = useNavigate()

  return (
    <PasswordForm
      eventSlug={eventSlug}
      onCancel={() => {
        navigate(routes.index)
        onClose()
      }}
      onSuccess={({ password }) => {
        onSuccess(password)
      }}
    />
  )
}

export default PasswordModal
