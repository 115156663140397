import React from 'react'
import {
  trackWindowScroll,
  LazyComponentProps,
} from 'react-lazy-load-image-component'

import { IVideoImageGroup } from '../../../data/Event/types'

import SingleImage from './SingleImage'
import * as S from './styled'

type IProps = LazyComponentProps & {
  data: ImmutableArray<IVideoImageGroup>
}

const VideoRoll: React.FC<IProps> = ({ data, scrollPosition }) => {
  return (
    <S.Wrap>
      {data.map((item, index) => (
        <SingleImage
          key={`${item.thumbnail.id}_${index}`}
          video={item.video}
          thumbnail={item.thumbnail}
          scrollPosition={scrollPosition}
        />
      ))}
    </S.Wrap>
  )
}

export default trackWindowScroll(VideoRoll)
