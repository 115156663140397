import styled, { css } from 'styled-components'

type IRoundButtonProps = {
  size: 'small' | 'normal'
}

const roundButtonStyles = (props: IRoundButtonProps) => css<IRoundButtonProps>`
  border: 0;
  outline: 0;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  ${({ size }) => {
    switch (size) {
      case 'small':
        return css`
          width: 56px;
          height: 56px;
          border-radius: 56px;
        `
      case 'normal':
        return css`
          width: 80px;
          height: 80px;
          border-radius: 80px;

          svg {
            width: 32px;
            height: 32px;
          }
        `
    }
  }}
`

export const ButtonRound = styled.button<IRoundButtonProps>`
  ${(props) => roundButtonStyles(props)}

  cursor: pointer;

  &:focus {
    outline: 0;
  }
`

export const ButtonRoundAnchor = styled.a<IRoundButtonProps>`
  ${(props) => roundButtonStyles(props)}

  text-decoration: none;
  color: transparent;

  &:hover {
    text-decoration: none;
  }
`
