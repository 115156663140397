import styled from 'styled-components'
import { H1, SmallText } from '../../constants/typography'
import colors from '../../constants/colors'

export const TextWrap = styled.div`
  margin: 16px 0 32px;
  text-align: center;
`

export const Title = styled(H1)`
  svg {
    margin-left: 12px;
  }
`

export const SubTitle = styled(SmallText)`
  color: ${colors.alpha};
`

export const SpinnerWrap = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`
