import React from 'react'
import smoothscroll from 'smoothscroll-polyfill'

import ArrowBack from '../../static/Svg/ArrowBack'
import * as S from './styled'

smoothscroll.polyfill()

const UpButton: React.FC = () => {
  const handleClick = () => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' })
  }

  return (
    <S.Button onClick={handleClick} size={'small'}>
      <ArrowBack isInverted={true} />
    </S.Button>
  )
}

export default UpButton
