import styled from 'styled-components'
import { ButtonRoundAnchor, ButtonRound } from '../../constants/buttons'
import colors from '../../constants/colors'
import { SmallText } from '../../constants/typography'

export const FixedWrap = styled.div`
  position: fixed;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;

  background: white;
`

export const InnerWrap = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`

export const ActionsWrap = styled.div`
  position: relative;

  display: flex;
  justify-content: space-evenly;
  align-items: center;

  margin-bottom: 40px;
`

export const ButtonBack = styled(ButtonRoundAnchor)`
  background: ${colors.secondary};
`

export const ButtonDownload = styled(ButtonRoundAnchor)`
  background: ${colors.alpha};
`

export const ButtonEmail = styled(ButtonRound)`
  background: ${colors.secondary};
`

export const ButtonCancel = styled(ButtonRound)`
  background: ${colors.secondary};
`

export const ButtonEmailSubmit = styled(ButtonRound)<{
  variant: 'default' | 'error'
}>`
  background: ${({ variant }) =>
    variant === 'error' ? colors.error : colors.alpha};
`

export const ButtonLabel = styled(SmallText)`
  position: absolute;
  bottom: -30px;
  color: ${colors.alpha};
  opacity: 0.65;
`

interface IInputWithErrorProps {
  hasError: boolean
}

export const InputMail = styled.input<IInputWithErrorProps>`
  width: 80%;
  margin: 20px auto 30px 10%;

  text-align: center;
  font-size: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  border: 0;
  border-bottom: 2px solid
    ${({ hasError }) => (hasError ? colors.error : colors.gray100)};

  &:focus {
    outline: 0;
  }
`
