import styled from 'styled-components'
import colors from '../../constants/colors'
import { ButtonRound } from '../../constants/buttons'

export const Button = styled(ButtonRound)`
  background: ${colors.alpha};

  position: fixed;
  right: 2vw;
  bottom: 2vw;
  z-index: 100;

  svg {
    transform: rotate(90deg);
  }
`
