import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { radius } from '../../../../constants/styles'
import colors from '../../../../constants/colors'

type ImgWrapProps = React.ComponentProps<typeof Link> & { isLoading: boolean }

export const ImgWrap = styled(({ isLoading, ...props }: ImgWrapProps) => (
  <Link {...props} />
))<{ isLoading: boolean }>`
  border-radius: ${radius.primary};
  overflow: hidden;
  position: relative;

  width: 30vw;
  height: 30vw;
  background: ${colors.gray100};

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: ${({ isLoading }) => (isLoading ? 0 : 1)};
    transition: all 200ms;
  }

  @media only screen and (min-width: 800px) {
    width: 100%;
    height: 220px;

    transition: all 200ms;

    &:hover {
      transform: scale(1.07);
    }
  }
`

export const LoaderWrap = styled.span`
  position: absolute;
  width: 20%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  svg {
    width: 100%;
  }
`
