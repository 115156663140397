// event loading
export const LOAD_EVENT_REQUEST = '@event/LOAD_EVENT_REQUEST'
export const LOAD_EVENT_SUCCESS = '@event/LOAD_EVENT_SUCCESS'
export const LOAD_EVENT_FAILURE = '@event/LOAD_EVENT_FAILURE'
// video selection
export const SAVE_SELECTED_VIDEO_ID = '@event/SAVE_SELECTED_VIDEO_ID'
// store reset
export const RESET_EVENT_STORE = '@event/RESET_EVENT_STORE'

const CONSTANTS = {
  LOAD_EVENT_REQUEST: LOAD_EVENT_REQUEST,
  LOAD_EVENT_SUCCESS: LOAD_EVENT_SUCCESS,
  LOAD_EVENT_FAILURE: LOAD_EVENT_FAILURE,
  SAVE_SELECTED_VIDEO_ID: SAVE_SELECTED_VIDEO_ID,
  RESET_EVENT_STORE: RESET_EVENT_STORE,
}

export default CONSTANTS
