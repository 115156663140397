import React from 'react'

import { externalRoutes } from '../../constants/routes'
import Logo from '../../static/Img/logo.png'
import * as S from './styled'

const Header: React.FC = () => {
  return (
    <S.Header>
      <a
        href={externalRoutes.projectWebsite}
        target='_blank'
        rel='noopener noreferrer'
      >
        <S.Img src={Logo} />
      </a>
    </S.Header>
  )
}

export default Header
