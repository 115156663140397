import React from 'react'

import Layout from '../../components/Layout'
import * as S from './styled'

const EventDetail: React.FC = () => {
  return (
    <Layout>
      <S.SubTitle>Pro zobrazení otevřete správnou adresu</S.SubTitle>
    </Layout>
  )
}

export default EventDetail
