import React from 'react'
import ReactDOM from 'react-dom'

import App from './components/App'
import * as serviceWorker from './serviceWorker'

import GlobalStyles from './globalStyles'

import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from './data/store'

const render = () =>
  ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
        <GlobalStyles />
      </PersistGate>
    </Provider>,
    document.getElementById('root')
  )

// Tiny hack to fix HMR, because @types/webpack-env is not loaded correctly
// TODO: remove type assertion
if ((module as NodeModule).hot) {
  ;(module as NodeModule).hot.accept('./components/App', render)
}

render()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
