import { removeLeadingSlash } from '../utils/stringUtils'

export const externalRoutes = {
  projectWebsite: 'https://pixito.cz/',
}

const routes = {
  index: '/',
  eventDetail: (eventSlug: string) => `/${removeLeadingSlash(eventSlug)}`,
  videoDetail: (eventSlug: string, videoId: string) =>
    `/${removeLeadingSlash(eventSlug)}/${videoId}`,
}

export default routes
