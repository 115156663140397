import React, { useCallback, useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import type {} from 'redux-thunk/extend-redux'

import {
  selectEventName,
  selectEventFiles,
  selectSelectedVideoId,
  selectSelectedFileGroup,
  selectEventIsPasswordRequired,
} from '../../data/Event/selectors'
import { handleGetEventBySlug } from '../../data/Event/thunks'
import { useInterval } from '../../utils/useInterval'
import routes from '../../constants/routes'
import { saveSelectedVideoId } from '../../data/Event/actions'

import VideoRoll from './VideoRoll'
import VideoDetail from '../VideoDetail'
import Layout from '../../components/Layout'
import Footer from '../../components/Footer'
import UpButton from '../../components/UpButton'
import Spinner from '../../static/Svg/Spinner'
import * as S from './styled'
import PasswordModal from './PasswordModal'
import Lock from '../../static/Svg/Lock'

const EventDetail: React.FC = () => {
  // connect redux
  const eventName = useSelector(selectEventName)
  const eventIsPasswordRequired = useSelector(selectEventIsPasswordRequired)
  const eventFiles = useSelector(selectEventFiles)
  const selectedVideoId = useSelector(selectSelectedVideoId)
  const selectedGroup = useSelector(selectSelectedFileGroup)
  const dispatch = useDispatch()

  // router
  const routeParams = useParams<{
    eventSlug: string
    selectedVideoId?: string
  }>()
  const navigate = useNavigate()

  const [isVideoRollRendered, setIsVideoRollRendered] = useState(
    !routeParams.selectedVideoId
  )

  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false)
  const [eventPassword, setEventPassword] = useState(
    localStorage.getItem(`password_${routeParams.eventSlug}`) ?? undefined
  )
  const saveEventPassword = useCallback(
    (password: string) => {
      localStorage.setItem(`password_${routeParams.eventSlug}`, password)
      setEventPassword(password)
    },
    [routeParams.eventSlug]
  )

  // fetch new data on component mount
  useEffect(() => {
    const fetchData = async () => {
      const { success, requirePassword } = await dispatch(
        handleGetEventBySlug(routeParams.eventSlug, eventPassword)
      )
      console.log('success', success, 'requirePassword', requirePassword)
      if (!success) {
        if (requirePassword) {
          setIsPasswordModalOpen(true)
        } else {
          navigate(routes.index)
        }
      }
    }
    fetchData()
  }, [dispatch, navigate, routeParams.eventSlug, eventPassword])

  // fetch new data in 30 sec intervals
  useInterval(
    () => {
      dispatch(handleGetEventBySlug(routeParams.eventSlug, eventPassword))
    },
    30 * 1000,
    [routeParams.eventSlug, eventPassword]
  )

  // restore selected video from url param
  useEffect(() => {
    if (routeParams.selectedVideoId) {
      dispatch(saveSelectedVideoId(routeParams.selectedVideoId))
    }
    if (!routeParams.selectedVideoId && selectedVideoId) {
      dispatch(saveSelectedVideoId(''))
    }
  }, [dispatch, routeParams.selectedVideoId, selectedVideoId])

  useEffect(() => {
    if (!selectedVideoId || !selectedGroup) {
      setIsVideoRollRendered(true)
    }
  }, [selectedVideoId, selectedGroup])

  const renderText = (quantity: number) => {
    switch (quantity) {
      case 1:
        return 'video'
      case 2:
      case 3:
      case 4:
        return 'videa'
      default:
        return 'videí'
    }
  }

  return (
    <Layout>
      <React.Fragment>
        {eventName || eventFiles.length > 0 ? (
          <S.TextWrap>
            <S.Title>
              {eventName} {eventIsPasswordRequired ? <Lock /> : ''}
            </S.Title>
            <S.SubTitle>
              {eventFiles.length} {renderText(eventFiles.length)}
            </S.SubTitle>
          </S.TextWrap>
        ) : isPasswordModalOpen ? (
          <PasswordModal
            eventSlug={routeParams.eventSlug!}
            onClose={() => setIsPasswordModalOpen(false)}
            onSuccess={(password) => {
              saveEventPassword(password)
              setIsPasswordModalOpen(false)
            }}
          />
        ) : (
          <S.SpinnerWrap>
            <Spinner />
          </S.SpinnerWrap>
        )}
        {/* Render video detail as a popup so the video roll stays mounted */}
        {(!selectedVideoId || !selectedGroup || isVideoRollRendered) && (
          <VideoRoll data={eventFiles}></VideoRoll>
        )}
        {selectedVideoId && selectedGroup && (
          <VideoDetail
            data={selectedGroup}
            eventSlug={routeParams.eventSlug!}
          />
        )}
        {eventFiles.length > 12 && <UpButton />}
        <Footer />
      </React.Fragment>
    </Layout>
  )
}

export default EventDetail
