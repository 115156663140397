import styled from 'styled-components'
import colors from './colors'

export const fontFamily = {
  primary: 'Poppins',
}

export const fontWeight = {
  medium: '500',
  semiBold: '600',
  bold: '700',
}

export const H1 = styled.h1`
  font-size: 2.6rem;
  line-height: normal;
  font-weight: ${fontWeight.bold};
  color: ${colors.secondary};
  text-align: center;
`

export const SmallText = styled.p`
  text-transform: uppercase;
  margin-top: 8px;
  font-size: 1.3rem;
  color: ${colors.gray300};
  font-weight: ${fontWeight.medium};
`
