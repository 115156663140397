import { action } from 'typesafe-actions'
import {
  LOAD_EVENT_REQUEST,
  LOAD_EVENT_SUCCESS,
  LOAD_EVENT_FAILURE,
  RESET_EVENT_STORE,
  SAVE_SELECTED_VIDEO_ID,
} from './constants'
import { IEvent } from './types'

export const loadEventRequest = () => action(LOAD_EVENT_REQUEST)

export const loadEventSuccess = (event: IEvent) =>
  action(LOAD_EVENT_SUCCESS, event)

export const loadEventFailure = () => action(LOAD_EVENT_FAILURE)

export const resetEventStore = () => action(RESET_EVENT_STORE)

export const saveSelectedVideoId = (videoId: string) =>
  action(SAVE_SELECTED_VIDEO_ID, videoId)
