import config from '../config'

const { REACT_APP_API_URL: apiUrl, REACT_APP_API_KEY: apiKey } = config

export interface IApiClientError {
  statusCode: number
}

interface IFetchParams {
  url: string
  headers?: HeadersInit
  options?: RequestInit
}

const doFetch = async ({ url, headers = {}, options = {} }: IFetchParams) => {
  return fetch(`${apiUrl}${url}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...headers,
    },
    ...options,
  })
}

const hasError = (res: Response): boolean => !res.ok

const api = async <T>(url: string, options: RequestInit = {}): Promise<T> => {
  // Prepare token
  const authHeader = { Authorization: `Bearer ${apiKey}` }

  // Do the api call
  let res = await doFetch({ url, headers: authHeader, options })

  // Handle errors
  if (hasError(res)) {
    const error: IApiClientError = { statusCode: res.status }
    throw error
  }

  // Process success
  return res.json()
}

export const apiPost = async <T>(
  url: string,
  options: RequestInit = {}
): Promise<T> => api(url, { method: 'POST', ...options })

export default api
