import styled from 'styled-components'
import colors from '../../../constants/colors'
import { radius } from '../../../constants/styles'

export const VideoWrap = styled.div`
  position: relative;
  width: 100%;
  min-height: 180px;

  border-radius: ${radius.primary};
  overflow: hidden;
  font-size: 0;
  background: ${colors.gray100};

  video {
    width: 100%;
    position: relative;
  }
`

export const LoaderWrap = styled.span`
  position: absolute;
  width: 20%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  svg {
    width: 100%;
  }
`
