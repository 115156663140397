import React, { useState } from 'react'

import { validateEventPassword } from '../../../../utils/helpers'
import { requestCheckEventpassword } from '../../../../api/events'

import Close from '../../../../static/Svg/Close'
import ArrowBack from '../../../../static/Svg/ArrowBack'
import Spinner from '../../../../static/Svg/Spinner'
import * as S from './styled'

enum SendingState {
  IDLE = 'idle',
  RUNNING = 'running',
  DONE = 'done',
  ERROR = 'error',
}

type IProps = {
  eventSlug: string
  onCancel: () => void
  onSuccess: (formValues: { password: string }) => void
}

const PasswordForm: React.FC<IProps> = ({ eventSlug, onCancel, onSuccess }) => {
  const [mailValue, setMailValue] = useState('')
  const [hasError, setHasError] = useState(false)

  const [sendingState, setSendingState] = useState<SendingState>(
    SendingState.IDLE
  )

  const handleSubmit = async (
    event:
      | React.FormEvent<HTMLFormElement>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    console.log('fsdfsdfd')
    event.preventDefault()
    if (sendingState !== SendingState.IDLE) {
      return
    }

    if (!validateEventPassword(mailValue)) {
      setHasError(true)
    }

    try {
      setSendingState(SendingState.RUNNING)
      await requestCheckEventpassword({
        slug: eventSlug,
        password: mailValue,
      })

      setSendingState(SendingState.DONE)
      onSuccess({ password: mailValue })
      setTimeout(() => {
        setSendingState(SendingState.IDLE)
      }, 2000)
    } catch (error) {
      console.error(error)
      setSendingState(SendingState.ERROR)
      setTimeout(() => {
        setSendingState(SendingState.IDLE)
      }, 5000)
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHasError(false)
    setMailValue(event.target.value)
  }

  return (
    <S.Wrap>
      <form onSubmit={handleSubmit}>
        <S.Input
          autoFocus
          name='email'
          placeholder='zadej heslo...'
          value={mailValue}
          onChange={handleChange}
          hasError={hasError}
        />
        {sendingState === SendingState.ERROR && (
          <S.ErrorText>Špatné heslo</S.ErrorText>
        )}
        <S.ActionsWrap>
          <S.ButtonCancel
            onClick={onCancel}
            size='small'
            title='Zrušit'
            type='reset'
          >
            <Close isInverted={true} />
            <S.ButtonLabel>Zrušit</S.ButtonLabel>
          </S.ButtonCancel>
          <S.ButtonEmailSubmit
            onClick={handleSubmit}
            size='normal'
            title='Pokračovat'
            type='submit'
            variant={sendingState === SendingState.ERROR ? 'error' : 'default'}
          >
            {sendingState === SendingState.RUNNING ? (
              <Spinner isInverted={true} />
            ) : (
              <ArrowBack isInverted={true} />
            )}

            <S.ButtonLabel>Pokračovat</S.ButtonLabel>
          </S.ButtonEmailSubmit>
        </S.ActionsWrap>
      </form>
    </S.Wrap>
  )
}

export default PasswordForm
