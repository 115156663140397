import styled from 'styled-components'
import colors from '../../../constants/colors'
import { radius } from '../../../constants/styles'

export const ImageWrap = styled.div`
  position: relative;
  width: 100%;
  min-height: 180px;

  border-radius: ${radius.primary};
  overflow: hidden;
  font-size: 0;
  background: ${colors.gray100};

  video {
    width: 100%;
    position: relative;
  }
`

export const Image = styled.img`
  aspect-ratio: 1;
  width: 100%;
`
