import React, { useState } from 'react'

import SaveAlt from '../../../static/Svg/SaveAlt'
import Spinner from '../../../static/Svg/Spinner'
import Done from '../../../static/Svg/Done'

import * as S from '../styled'

enum DownloadingState {
  IDLE = 'idle',
  RUNNING = 'running',
  DONE = 'done',
}

type IProps = {
  downloadUrl: string
}

const DownloadButton: React.FC<IProps> = ({ downloadUrl }) => {
  const [downloadingState, setDownloadingState] = useState<DownloadingState>(
    DownloadingState.IDLE
  )
  // emulate downloading progress
  const handleDownloadClick = () => {
    setDownloadingState(DownloadingState.RUNNING)
    setTimeout(() => {
      setDownloadingState(DownloadingState.DONE)
    }, 5000)
    setTimeout(() => {
      setDownloadingState(DownloadingState.IDLE)
    }, 8000)
  }

  return (
    <S.ButtonDownload
      href={downloadUrl}
      onClick={handleDownloadClick}
      download={true}
      size='normal'
      title='Stáhnout'
    >
      {downloadingState === DownloadingState.IDLE && (
        <SaveAlt isInverted={true} />
      )}
      {downloadingState === DownloadingState.RUNNING && (
        <Spinner isInverted={true} />
      )}
      {downloadingState === DownloadingState.DONE && <Done isInverted={true} />}
      <S.ButtonLabel>Stáhnout</S.ButtonLabel>
    </S.ButtonDownload>
  )
}

export default DownloadButton
