import React from 'react'

import * as S from './styled'

type IProps = {
  gifSrc: string
}

const GifPlayer: React.FC<IProps> = ({ gifSrc }) => {
  return (
    <S.ImageWrap>
      <S.Image src={gifSrc} />
    </S.ImageWrap>
  )
}

export default GifPlayer
