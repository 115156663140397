import React, { useState } from 'react'

import { requestPostSendByMail } from '../../../api/actions'
import { validateEmail } from '../../../utils/helpers'

import Close from '../../../static/Svg/Close'
import Send from '../../../static/Svg/Send'
import Spinner from '../../../static/Svg/Spinner'
import Done from '../../../static/Svg/Done'
import * as S from '../styled'

enum SendingState {
  IDLE = 'idle',
  RUNNING = 'running',
  DONE = 'done',
  ERROR = 'error',
}

type IProps = {
  onCancel: () => void
  resourceId: string
}

const MailForm: React.FC<IProps> = ({ onCancel, resourceId }) => {
  const [mailValue, setMailValue] = useState('')
  const [hasError, setHasError] = useState(false)

  const [sendingState, setSendingState] = useState<SendingState>(
    SendingState.IDLE
  )

  const handleSubmit = async (
    event:
      | React.FormEvent<HTMLFormElement>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault()
    if (sendingState !== SendingState.IDLE) {
      return
    }
    if (!validateEmail(mailValue)) {
      setHasError(true)
      console.error('error sending mail to', mailValue)
    }

    try {
      setSendingState(SendingState.RUNNING)
      await requestPostSendByMail({ recipient: mailValue, resourceId })
      setSendingState(SendingState.DONE)
      setTimeout(() => {
        setSendingState(SendingState.IDLE)
        onCancel()
      }, 2000)
    } catch (error) {
      console.error(error)
      setSendingState(SendingState.ERROR)
      setTimeout(() => {
        setSendingState(SendingState.IDLE)
      }, 5000)
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHasError(false)
    setMailValue(event.target.value)
  }

  return (
    <form onSubmit={handleSubmit}>
      <S.InputMail
        autoFocus
        type='email'
        name='email'
        placeholder='zadej email...'
        value={mailValue}
        onChange={handleChange}
        hasError={hasError}
      />
      <S.ActionsWrap>
        <S.ButtonCancel
          onClick={onCancel}
          size='small'
          title='Zrušit'
          type='reset'
        >
          <Close isInverted={true} />
          <S.ButtonLabel>Zrušit</S.ButtonLabel>
        </S.ButtonCancel>
        <S.ButtonEmailSubmit
          onClick={handleSubmit}
          size='normal'
          title='Odeslat'
          type='submit'
          variant={sendingState === SendingState.ERROR ? 'error' : 'default'}
        >
          {sendingState === SendingState.IDLE && <Send isInverted={true} />}
          {sendingState === SendingState.RUNNING && (
            <Spinner isInverted={true} />
          )}
          {sendingState === SendingState.DONE && <Done isInverted={true} />}
          {sendingState === SendingState.ERROR && <Close isInverted={true} />}
          <S.ButtonLabel>
            {sendingState === SendingState.ERROR ? 'Chyba' : 'Odeslat'}
          </S.ButtonLabel>
        </S.ButtonEmailSubmit>
      </S.ActionsWrap>
    </form>
  )
}

export default MailForm
