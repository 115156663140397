import React, { useRef, useState, useEffect } from 'react'

import Spinner from '../../../static/Svg/Spinner'
import * as S from './styled'

type IProps = {
  videoSrc: string
}

const VideoPlayer: React.FC<IProps> = ({ videoSrc }) => {
  const [isLoading, setIsLoading] = useState(true)

  const videoRef = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    // fix this issue https://github.com/facebook/react/issues/6544
    if (videoRef.current) {
      videoRef.current.setAttribute('muted', '1')
      videoRef.current.setAttribute('playsinline', '1')
      videoRef.current.play()
    }
  }, [])

  const togglePlaying = () => {
    if (videoRef.current) {
      videoRef.current.paused
        ? videoRef.current.play()
        : videoRef.current.pause()
    }
  }

  return (
    <S.VideoWrap>
      {isLoading && (
        <S.LoaderWrap>
          <Spinner />
        </S.LoaderWrap>
      )}
      <video
        ref={videoRef}
        onClick={togglePlaying}
        playsInline={true}
        muted={true}
        autoPlay={true}
        loop={true}
        onLoadedData={() => setIsLoading(false)}
        src={videoSrc}
      />
    </S.VideoWrap>
  )
}

export default VideoPlayer
