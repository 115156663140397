import { IReduxState } from '../store'

export const selectEventName = (state: IReduxState) => state.event.name

export const selectEventIsPasswordRequired = (state: IReduxState) =>
  state.event.passwordRequired

export const selectEventSlug = (state: IReduxState) => state.event.slug

export const selectEventFiles = (state: IReduxState) => state.event.files

export const selectFileGroupByVideoId = (state: IReduxState, videoId: string) =>
  state.event.files.find((group) => group.video.id === videoId)

export const selectSelectedFileGroup = (state: IReduxState) =>
  selectFileGroupByVideoId(state, state.event.selectedVideoId)

export const selectSelectedVideoId = (state: IReduxState) =>
  state.event.selectedVideoId
