const colors = {
  // main colors
  primary: '#fff',
  secondary: '#30323C',

  // theme colors
  alpha: '#F0334C',

  // grays
  gray100: '#f1f1f1',
  gray300: '#A4A4A4',

  // state colors
  success: '#37d773',
  error: '#F4282D',
}

export default colors
